<template>
  <!-- 弹框 -->
  <vh-dialog
    title="机器人列表"
    :visible.sync="dialogBot"
    :close-on-click-modal="false"
    :modal-append-to-body="false"
    class="botDialog"
    width="750px"
  >
    <vh-table
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%"
      max-height="500"
      :header-cell-style="{ background: '#f7f7f7', color: '#666', height: '56px' }"
    >
      <vh-table-column label="头像" width="80">
        <template slot-scope="scope">
          <div class="flexBox">
            <div class="gift-cover">
              <img :src="scope.row.avatar" alt="" :style="scope.row.cssStyle" />
            </div>
          </div>
        </template>
      </vh-table-column>
      <vh-table-column label="昵称" width="150" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.nickname }}</span>
        </template>
      </vh-table-column>
      <vh-table-column label="发言内容" show-overflow-tooltip>
        <template slot-scope="scope">
          {{ scope.row.chat_content }}
        </template>
      </vh-table-column>
      <div class="" slot="empty">
        <null-page nullType="null" class="search-no-data" :height="60"></null-page>
      </div>
    </vh-table>
  </vh-dialog>
</template>

<script>
  import NullPage from '../../PlatformModule/Error/nullPage';
  import { imgParseMap } from '@/utils/imgSizeMap';
  import { parseQueryString } from '@/utils/utils';

  export default {
    name: 'BotDialog',
    data() {
      return {
        tableData: [], // 列表数据
        dialogBot: false,
        topicId: ''
      };
    },
    props: {
      topic_id: {
        type: String,
        default: ''
      }
    },
    components: {
      NullPage
    },
    computed: {},
    methods: {
      async getList() {
        const params = {
          topic_id: this.topicId
        };
        let res = await this.$fetch('getBotList', params);
        if (res.code == 200 && res.data) {
          this.tableData = res.data.list;
          for (let index = 0; index < this.tableData.length; index++) {
            let obj = parseQueryString(this.tableData[index].avatar);
            let currentMode = obj.mode || 1;
            this.tableData[index].cssStyle = {
              ...imgParseMap[currentMode]
            };
          }
        }
      },
      async open(id) {
        this.topicId = id ? id : this.topic_id;
        await this.getList();
        this.dialogBot = true;
      }
    }
  };
</script>

<style lang="less">
  .botDialog {
    .flexBox {
      display: flex;
      align-items: center;
      .name {
        margin-left: 10px;
      }
      .gift-cover {
        display: inline-block;
        width: 40px;
        min-width: 40px;
        height: 40px;
        // margin: 3px 0;
        background: #ffffff;
        border-radius: 50%;
        // border-radius: 4px;
        // border: 1px solid #e6e6e6;
        img {
          width: 100%;
          height: 100%;
          object-fit: scale-down;
          border-radius: 50%;
        }
      }
    }
  }
</style>
