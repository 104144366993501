<template>
  <div class="AI-chat-card" v-loading="loading">
    <pageTitle pageTitle="AI聊天">
      <div slot="content">
        1.设置聊天机器人可活跃直播间氛围，可发送礼物和聊天，不参与抽奖等互动，也不影响观看数据
        <br />
        2.仅在直播中生效，聊天主题仅能同时生效1个主题
        <br />
        3.问答和私聊不支持AI聊天机器人
        <br />
        4.AI聊天机器人发送的聊天内容，后台依旧进行统计，但会进行标注
        <br />
        5.AI聊天机器人依旧需要经过聊天严禁词和人工审核逻辑
        <br />
        6.定时开启方式，同一场次下已推送过的主题再次编辑后，不会定时推送，建议进行复制
      </div>
    </pageTitle>
    <div class="AI-chat-main" v-if="auth_show && !loading">
      <template v-if="noData">
        <null-page text="您还没有内容，快来创建吧！" nullType="button">
          <vh-button type="primary" class="createBtn" round @click="createTopic" v-preventReClick>
            创建
          </vh-button>
          <vh-button
            v-if="isInWebinar && auth_show_lib"
            plain
            class="createBtn"
            type="default"
            @click="openLibrary"
            round
          >
            资料库
          </vh-button>
        </null-page>
      </template>
      <template v-else>
        <div class="search-data">
          <vh-button size="medium" class="length104" type="primary" @click="createTopic" round>
            创建
          </vh-button>
          <vh-button
            size="medium"
            plain
            v-if="isInWebinar && auth_show_lib"
            class="length104"
            type="default"
            @click="openLibrary"
            round
          >
            资料库
          </vh-button>
          <vh-button
            size="medium"
            class="head-btn length104 transparent-btn"
            round
            plain
            @click="allDelete(null)"
            :disabled="!topic_ids.length"
          >
            批量删除
          </vh-button>

          <vh-input
            round
            class="search-tag"
            placeholder="请输入内容主题"
            v-model="keyword"
            clearable
            @clear="initQueryUserList"
            @keyup.enter.native="initQueryUserList"
          >
            <i class="vh-icon-search vh-input__icon" slot="prefix" @click="initQueryUserList"></i>
          </vh-input>
          <vh-select
            round
            size="medium"
            clearable
            v-model="topicStatus"
            placeholder="全部状态"
            class="search-select"
            @change="initQueryUserList"
            v-if="isInWebinar"
          >
            <vh-option label="全部状态" value=""></vh-option>
            <vh-option
              v-for="(item, index) in statusGroup"
              :key="item + index"
              :label="item"
              :value="index"
            ></vh-option>
          </vh-select>
        </div>
        <div class="AI-chat-card-list">
          <div class="list-table-panel">
            <!-- 表格与分页 -->
            <vh-table
              ref="tableList"
              :data="tableList || []"
              @selection-change="handleTableCheckbox"
              :header-cell-style="{
                color: '#666',
                height: '56px'
              }"
            >
              <vh-table-column type="selection" width="60" align="left" fixed="left" />
              <vh-table-column
                align="left"
                v-for="(item, index) in userTableColumn"
                :key="index"
                :width="item.width"
                :label="item.label"
                :fixed="item.fixed"
                :show-overflow-tooltip="true"
              >
                <template slot-scope="scope">
                  <span :class="item.key">
                    <template v-if="item.key == 'topic_name'">
                      <span>{{ scope.row[item.key] }}</span>
                    </template>
                    <template v-else-if="item.key == 'activation_mode'">
                      <span>{{ scope.row[item.key] == 1 ? '定时开启' : '手动开启' }}</span>
                    </template>
                    <template v-else-if="item.key == 'timed_start_setting'">
                      <span>
                        {{ scope.row.time_setting_desc ? scope.row.time_setting_desc : '-' }}
                      </span>
                    </template>
                    <template v-else-if="item.key == 'chatbot_number'">
                      <span class="botNum" @click="openBot(scope.row)">
                        {{ scope.row[item.key] }}
                      </span>
                    </template>
                    <template v-else-if="item.key == 'sending_mode'">
                      <span>{{ scope.row[item.key] == 1 ? '顺序发言' : '随机发言' }}</span>
                    </template>
                    <template v-else-if="item.key == 'chat_interval'">
                      <span>{{ scope.row[item.key] }}秒</span>
                    </template>
                    <template v-else-if="item.key == 'gift_interval'">
                      <span>
                        {{ scope.row[item.key] == -1 ? '-' : `${scope.row[item.key]}秒` }}
                      </span>
                    </template>
                    <template v-else-if="item.key == 'status'">
                      <span>{{ statusGroup[scope.row[item.key]] }}</span>
                    </template>
                    <template v-else-if="item.key == 'updated_at'">
                      <span>{{ scope.row[item.key].substr(0, 16) }}</span>
                    </template>
                    <template v-else>{{ scope.row[item.key] }}</template>
                  </span>
                </template>
              </vh-table-column>
              <vh-table-column label="操作" align="left" width="160px" fixed="right">
                <template slot-scope="scope">
                  <vh-button type="text" class="tool" @click="copyTopic(scope.row)">复制</vh-button>
                  <vh-button
                    class="tool"
                    type="text"
                    :disabled="scope.row.status == 2"
                    @click="editTopic(scope.row)"
                  >
                    编辑
                  </vh-button>
                  <vh-button type="text" class="tool" @click="deleteTopic(scope.row)">
                    删除
                  </vh-button>
                </template>
              </vh-table-column>
              <div class="" slot="empty">
                <null-page class="search-no-data"></null-page>
              </div>
            </vh-table>
            <SPagination
              :total="total || 0"
              :page-size="limit"
              :currentPage="pageNumber"
              @current-change="currentChangeHandler"
              @size-change="handleSizeChange"
            ></SPagination>
          </div>
          <!-- 无消息内容 -->
        </div>

        <Bot ref="bot"></Bot>
      </template>
      <ChoseTopic :webinar_id="isInWebinar" @selected="selectedTopic" ref="choseTopic"></ChoseTopic>
    </div>

    <div v-else-if="!loading">
      <null-page
        text="AI聊天为高级功能，开通后您可以设置AI聊天机器人活跃直播间氛围。<br/>联系您的客户经理获取权限后方可使用。"
        nullType="setting"
      >
        <vh-button type="primary" round @click="openChat">联系客服</vh-button>
      </null-page>
    </div>

    <!-- 开播按钮 -->
    <begin-play
      :webinarId="isInWebinar"
      v-if="$route.query.type != 5 && webinarState != 4 && isInWebinar"
    ></begin-play>
  </div>
</template>
<script>
  import NullPage from '../../PlatformModule/Error/nullPage.vue';
  import PageTitle from '@/components/PageTitle';
  import { sessionOrLocal, isEmbed } from '@/utils/utils';
  import env from '@/api/env';
  import Bot from './bot';
  import ChoseTopic from './choseTopic';
  import beginPlay from '@/components/beginBtn';

  export default {
    data() {
      return {
        isEmbed: isEmbed(),
        topic_ids: [],
        keyword: '',
        pos: 0,
        pageNumber: 1,
        limit: 20,
        total: 0,
        tableList: [],
        baseTableColumn: [
          {
            label: '内容主题',
            key: 'content_title',
            width: '180px',
            fixed: 'left'
          },
          {
            label: '开启方式',
            key: 'activation_mode',
            width: '120px'
          },
          {
            label: '时间设置',
            key: 'timed_start_setting',
            width: '220px'
          },
          {
            label: '机器人数量',
            key: 'chatbot_number',
            width: '100px'
          },
          {
            label: '发送方式',
            key: 'sending_mode',
            width: '120px'
          },
          {
            label: '聊天间隔',
            key: 'chat_interval',
            width: '100px'
          },
          {
            label: '礼物间隔',
            key: 'gift_interval',
            width: '100px'
          },
          {
            label: '状态',
            key: 'status',
            width: '100px'
          },
          {
            label: '更新时间',
            key: 'updated_at',
            width: '160px'
          }
        ],
        auth_show: false,
        auth_show_lib: false,
        webinarState: JSON.parse(sessionOrLocal.get('webinarState')), // 活动状态
        loading: true,
        statusGroup: ['未推送', '已推送', '推送中', '等待推送'],
        permission: {},
        topicStatus: '',
        noData: true
      };
    },
    computed: {
      isInWebinar() {
        return this.$route.params.id;
      },
      userTableColumn() {
        if (!this.isInWebinar) {
          return this.baseTableColumn.filter(item => {
            return item.key !== 'status';
          });
        }
        return this.baseTableColumn;
      }
    },
    components: {
      NullPage,
      PageTitle,
      Bot,
      ChoseTopic,
      beginPlay
    },
    created() {
      if (this.isInWebinar) {
        let permission = JSON.parse(sessionOrLocal.get('WEBINAR_PES', 'localStorage'));
        this.auth_show = !!permission['ai_chat'];
      } else {
        let permission = JSON.parse(sessionOrLocal.get('SAAS_VS_PES', 'localStorage'));
        this.auth_show = !!permission['ai_chat'];
      }

      let permission = JSON.parse(sessionOrLocal.get('SAAS_VS_PES', 'localStorage'));
      this.auth_show_lib = !!permission['ai_chat'];
    },
    mounted() {
      this.initQueryUserList();
    },
    methods: {
      // 页码改变按钮事件
      currentChangeHandler(current) {
        this.pageNumber = current;
        this.pos = parseInt((current - 1) * this.limit);
        this.getTopicTableList();
      },
      // 每页显示个数选择器的选项设置 改变按钮事件
      handleSizeChange(current) {
        this.pageNumber = 1;
        this.limit = current;
        this.pos = 0;
        this.getTopicTableList();
      },
      // 复选框操作
      handleTableCheckbox(val) {
        console.log(val);
        this.topic_ids = val.map(item => item.id);
      },
      initQueryUserList() {
        this.pos = 0;
        this.pageNumber = 1;
        this.limit = 20;
        // 表格切换到第一页
        this.$nextTick(() => {
          if (this.$refs.tableList && this.$refs.tableList.pageInfo) {
            this.$refs.tableList.pageInfo.pageNum = 1;
            this.$refs.tableList.pageInfo.pos = 0;
          }
          this.getTopicTableList();
        });
      },
      getTopicTableList(row) {
        this.loading = true;
        if (row) {
          this.pos = row.pos;
          this.pageNumber = row.pageNum;
        }
        // this.loading = true;
        let params = {
          keyword: this.keyword,
          webinar_id: this.isInWebinar,
          pos: this.pos,
          limit: this.limit,
          status: this.topicStatus
        };
        this.$fetch('getTopicList', this.$params(params)).then(res => {
          // 有数据但是当前页没有
          if (
            res &&
            res.code === 200 &&
            res.data &&
            res.data.list.length == 0 &&
            res.data.total != 0
          ) {
            this.getTopicTableList({ pos: 0, pageNum: 1 });
            return;
          }

          this.total = res.data.total;
          this.tableList = res.data.list;
          // console.log(this.tableList, '????????this.tableList');
          this.noData = this.topicStatus === '' && !this.keyword && this.tableList.length == 0;
          this.loading = false;
        });
      },
      deleteTopic(rows) {
        this.deleteConfirm(rows.id);
      },
      deleteConfirm(id) {
        this.$vhConfirm('删除后，当前AI聊天主题将无法使用，确认删除？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          customClass: 'zdy-message-box',
          lockScroll: false,
          roundButton: true,

          cancelButtonClass: 'zdy-confirm-cancel'
        }).then(() => {
          this.$fetch('deleteTopic', {
            topic_ids: id
          })
            .then(res => {
              if (res && res.code === 200) {
                this.$vhMessage({
                  message: `删除成功`,
                  showClose: true,
                  // duration: 0,
                  type: 'success',
                  customClass: 'zdy-info-box'
                });
                // 刷新页面
                this.$refs.tableList && this.$refs.tableList.clearSelection();
                this.getTopicTableList();
                this.topic_ids = [];
              }
            })
            .catch(res => {
              this.$vhMessage({
                message: res.msg || `删除失败`,
                showClose: true,
                // duration: 0,
                type: 'error',
                customClass: 'zdy-info-box'
              });
            });
        });
      },
      allDelete(id) {
        id = this.topic_ids.join(',');
        this.deleteConfirm(id);
      },
      createTopic() {
        if (this.isInWebinar) {
          //活动下
          this.$router.push({
            path: (this.isEmbed ? '/embed' : '') + `/live/AI/chat/create`,
            query: { webinar_id: this.isInWebinar }
          });
        } else {
          this.$router.push({
            path: (this.isEmbed ? '/embed' : '') + `/AI/chat/create`
          });
        }
      },
      editTopic(row) {
        if (this.isInWebinar) {
          //活动下
          this.$router.push({
            path: (this.isEmbed ? '/embed' : '') + `/live/AI/chat/edit/${row.id}`,
            query: { webinar_id: this.isInWebinar }
          });
        } else {
          this.$router.push({
            path: (this.isEmbed ? '/embed' : '') + `/AI/chat/edit/${row.id}`
          });
        }
      },
      copyTopic(row) {
        this.$fetch('copyTopic', {
          copied_id: row.id
        })
          .then(res => {
            if (res && res.code === 200) {
              this.$vhMessage({
                message: `复制成功`,
                showClose: true,
                // duration: 0,
                type: 'success',
                customClass: 'zdy-info-box'
              });
              // 刷新页面
              // this.$refs.tableList && this.$refs.tableList.clearSelection();
              this.getTopicTableList();
            }
          })
          .catch(res => {
            this.$vhMessage({
              message: res.msg || `复制失败`,
              showClose: true,
              // duration: 0,
              type: 'error',
              customClass: 'zdy-info-box'
            });
          });
      },
      openChat() {
        this.$vhall_paas_port({
          k: 100017,
          data: {
            business_uid: this.userId,
            user_id: '',
            webinar_id: '',
            s: '',
            refer: 5,
            report_extra: {},
            ref_url: '',
            req_url: ''
          }
        });
        window.open(`${env.staticLinkVo.kf}`, '_blank');
      },
      openBot(row) {
        this.$refs.bot.open(row.id);
      },
      openLibrary() {
        this.$refs.choseTopic.open();
      },
      selectedTopic() {
        this.initQueryUserList();
      }
    }
  };
</script>
<style lang="less">
  .AI-chat-card-list {
    background-color: #fff;
    padding: 24px;
    min-height: 676px;
  }
  .AI-chat-card {
    min-height: 50vh;
    .title-data {
      margin: 0 0 20px 0;
      text-align: left;
      line-height: 30px;
      span {
        font-size: 22px;
        font-weight: 600;
        color: #1a1a1a;
      }
      .subTitle {
        font-size: 14px;
        font-weight: 400;
        color: #666;
        margin-left: 12px;
      }
      .tool {
        color: #3562fa;
        font-weight: 400;
        font-size: 14px;
        cursor: pointer;
      }
      .button-tip {
        vertical-align: top;
      }
    }
    .AI-chat-main {
      .createBtn {
        width: 160px;
      }
      .vh-card__body {
        padding: 32px 24px;
      }
      .search-data {
        margin-bottom: 30px;
      }

      .search-tag {
        float: right;
        width: 220px;
        height: 36px;
        .vh-input__inner {
          height: 36px;
          .vh-input__icon {
            line-height: 36px;
          }
        }
      }
      .search-select {
        float: right;
        width: 140px;
        margin-right: 12px;
      }
      .list-table-panel {
        min-height: auto;
        .vh-table {
          .vh-table__body .cell {
            .webinar_num,
            .botNum,
            .tool {
              color: #3562fa;
              cursor: pointer;
            }

            .vh-button--text.is-disabled,
            .vh-button--text.is-disabled:focus,
            .vh-button--text.is-disabled:hover {
              color: #8c8c8c;
            }
            .vh-link.vh-link--primary.is-disabled {
              color: #8c8c8c;
            }
          }

          td:last-child,
          th:last-child {
            padding-right: 24px;
            text-align: right;
          }
        }
      }
    }
  }
  .no-live {
    .btn-list .vh-button {
      margin-right: 0;
    }
  }
</style>
<style lang="less">
  .AI-chat-card {
    .vh-input--limit .vh-input__inner {
      padding-right: 58px;
    }
  }
  .vh-tooltip__popper {
    max-width: 450px;
  }
</style>
