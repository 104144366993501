<template>
  <div class="topic-material">
    <vh-dialog
      title="选择主题"
      :before-close="handleClose"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="820px"
    >
      <div class="topic-material-list" v-loading="loading">
        <template v-if="!loading">
          <div v-if="isSearch || total">
            <div class="topic-material-header">
              <vh-input
                round
                size="medium"
                style="width: 180px"
                placeholder="请输入内容主题"
                v-clearEmoij
                :maxlength="100"
                v-model="keyword"
                @keyup.enter.native="getList($event, true)"
                clearable
                @clear="getList(true)"
              >
                <i
                  class="vh-icon-search vh-input__icon"
                  slot="prefix"
                  @click="getList($event, true)"
                ></i>
              </vh-input>
            </div>
            <!-- 表格 -->
            <vh-table
              ref="elTable"
              :data="tableData"
              tooltip-effect="dark"
              style="width: 100%"
              height="340px"
              v-loadMore="moreLoadData"
              @selection-change="changeDialogCheck"
              @select-all="checkAllRow"
            >
              <vh-table-column type="selection" width="55" fixed="left" align="left" />
              <vh-table-column
                align="left"
                v-for="(item, index) in baseTableColumn"
                :key="index"
                :width="item.width"
                :label="item.label"
                :fixed="item.fixed"
                :show-overflow-tooltip="true"
              >
                <template slot-scope="scope">
                  <span :class="item.key">
                    <template v-if="item.key == 'topic_name'">
                      <span>{{ scope.row[item.key] }}</span>
                    </template>
                    <template v-else-if="item.key == 'activation_mode'">
                      <span>{{ scope.row[item.key] == 1 ? '定时开启' : '手动开启' }}</span>
                    </template>
                    <template v-else-if="item.key == 'timed_start_setting'">
                      <span>
                        {{ scope.row.time_setting_desc }}
                      </span>
                    </template>
                    <template v-else-if="item.key == 'chatbot_number'">
                      <span class="botNum" @click="openBot(scope.row)">
                        {{ scope.row[item.key] }}
                      </span>
                    </template>
                    <template v-else-if="item.key == 'sending_mode'">
                      <span>{{ scope.row[item.key] == 1 ? '顺序发言' : '随机发言' }}</span>
                    </template>
                    <template v-else-if="item.key == 'chat_interval'">
                      <span>{{ scope.row[item.key] }}秒</span>
                    </template>
                    <template v-else-if="item.key == 'gift_interval'">
                      <span>
                        {{ scope.row[item.key] == -1 ? '-' : `${scope.row[item.key]}秒` }}
                      </span>
                    </template>
                    <template v-else-if="item.key == 'updated_at'">
                      <span>{{ scope.row[item.key] }}</span>
                    </template>
                    <template v-else>{{ scope.row[item.key] }}</template>
                  </span>
                </template>
              </vh-table-column>
              <div slot="empty">
                <null-page :nullType="'search'" v-if="!total" :height="60"></null-page>
              </div>
            </vh-table>
          </div>
          <null-page :nullType="'nullData'" text="您还没有内容，快来创建吧！" v-else :height="60">
            <vh-button type="primary" round @click="toCreate" v-preventReClick>创建</vh-button>
          </null-page>
          <div class="topic-material-footer" v-if="isSearch || total">
            <span class="footer-desc">
              当前选中
              <b>{{ selectTopics.length }}</b>
              个内容
            </span>
            <div>
              <vh-button plain round type="info" size="medium" @click="cancel">取消</vh-button>
              <vh-button
                v-preventReClick
                type="primary"
                round
                size="medium"
                @click="saveCheckHandle"
                :disabled="!(selectTopics && selectTopics.length > 0)"
              >
                确定
              </vh-button>
            </div>
          </div>
        </template>
      </div>
    </vh-dialog>
  </div>
</template>

<script>
  import NullPage from '../../PlatformModule/Error/nullPage.vue';
  export default {
    name: 'topicMaterial',
    components: {
      NullPage
    },
    props: {
      webinar_id: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        dialogVisible: false,
        total: 0,
        isSearch: false,
        isCheckAll: false,
        tableData: [],
        selectTopics: [],
        keyword: '',
        pageInfo: {
          page: 1,
          limit: 10
        },
        totalPages: 0,
        baseTableColumn: [
          {
            label: '内容主题',
            key: 'content_title',
            width: '180px',
            fixed: 'left'
          },
          {
            label: '开启方式',
            key: 'activation_mode',
            width: '120px'
          },
          {
            label: '时间设置',
            key: 'timed_start_setting',
            width: '220px'
          },
          {
            label: '机器人数量',
            key: 'chatbot_number',
            width: '100px'
          },
          {
            label: '发送方式',
            key: 'sending_mode',
            width: '120px'
          },
          {
            label: '聊天间隔',
            key: 'chat_interval',
            width: '100px'
          },
          {
            label: '礼物间隔',
            key: 'gift_interval',
            width: '100px'
          },
          {
            label: '更新时间',
            key: 'updated_at',
            width: '160px'
          }
        ],
        loading: true
      };
    },
    methods: {
      // 跳转到创建
      toCreate() {
        const { href } = this.$router.resolve({
          path: `/AI/chat/create`
        });
        window.open(href, '_blank');
      },
      handleClose(done) {
        this.pageInfo.page = 1;
        this.tableData = [];
        done();
      },
      moreLoadData() {
        if (this.pageInfo.page >= this.totalPages) {
          return false;
        }
        this.pageInfo.page++;
        this.getList();
      },
      // 获取资料库-弹出框内容
      getList(isClear, isInit) {
        this.isSearch = this.keyword ? true : false;
        if (isClear) {
          this.tableData = [];
          this.pageInfo.page = 1;
        }
        let params = {
          pos: (this.pageInfo.page - 1) * this.pageInfo.limit,
          limit: this.pageInfo.limit,
          keyword: this.keyword
        };

        this.$fetch('getTopicList', params)
          .then(res => {
            if (res && res.code === 200) {
              this.tableData.push(...(res.data.list || []));
              this.total = res.data.total;
              this.totalPages = Math.ceil(res.data.total / this.pageInfo.limit);
              if (this.isCheckAll) {
                this.$refs.elTable.toggleAllSelection();
              }
            } else {
              this.$vhMessage.error(res.msg);
            }
          })
          .finally(() => {
            this.loading = false;
          });
      },
      // 改变资料库-弹出框内容
      changeDialogCheck(val) {
        console.log(val);
        this.selectTopics = val.map(item => item.id);
      },
      checkAllRow(selection) {
        console.log('全选与非全选', selection);
        // 只要数量大于0，即是够了全选
        this.isCheckAll = selection && selection.length > 0;
      },
      open() {
        // 历史已经选择过的数据清空
        this.tableData = [];
        this.isSearch = false;
        this.dialogVisible = true;
        this.isCheckAll = false;
        this.keyword = '';
        this.pageInfo = {
          page: 1,
          limit: 10
        };
        this.getList(true, true);
      },
      saveCheckHandle() {
        //资料库选择
        console.log('资料库选择');
        let ids = this.selectTopics.join(',');
        this.$fetch('copyTopic2Webinar', {
          webinar_id: this.webinar_id,
          copied_ids: ids
        })
          .then(res2 => {
            if (res2.code == 200) {
              this.$emit('selected', '');
              this.cancel();
            }
          })
          .catch(res => {
            this.$vhMessage({
              message: res.message || `保存失败`,
              showClose: true,
              // duration: 0,
              type: 'error',
              customClass: 'zdy-info-box'
            });
          });
      },
      cancel() {
        this.tableData = [];
        this.selectTopics = [];
        try {
          this.$refs.elTable.clearSelection();
        } catch (e) {
          console.log(e);
        }
        document.querySelector('.topic-material .vh-table__body-wrapper').scrollTop = 0;
        this.dialogVisible = false;
      }
    }
  };
</script>

<style lang="less">
  .topic-material {
    &-list {
      min-height: 378px;
      .vh-radio-group {
        width: 100%;
        max-width: 100%;
      }
      .vh-radio__label {
        display: none;
      }
      .vh-table td:first-child,
      .vh-table th:first-child {
        padding-left: 24px;
      }
    }
    &-header {
      padding-bottom: 24px;
      overflow: hidden;
    }
    .topic-cover {
      width: 70px;
      height: 70px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    &-footer {
      margin-top: 24px;
      display: flex;
      justify-content: space-between;
      .footer-desc {
        line-height: 36px;
        color: #262626;
      }
      b {
        color: #fb2626;
      }
    }
    .highLight {
      color: #0a7ff5;
      cursor: pointer;
    }
  }
</style>
